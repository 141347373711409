// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';
import Swiper from 'swiper';
import { Scrollbar, Grid } from 'swiper/modules';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function fixedHeader() {
  if ($(window).scrollTop() > 0) {
    $('.header').addClass('fixed');
  } else {
    $('.header').removeClass('fixed');
  }
}

function offcanvasPadding() {
  if ($(window).width() > 1024) {
    let headerHeight = $('.header__top').outerHeight();
    $('.offcanvas > div').css('padding-top', headerHeight + 50);
    $(
      '.request_form__container, .offcanvas__left__wrapper, .offcanvas__middle--container'
    ).css('height', 'calc(100vh - ' + (headerHeight + 50) + 'px)');
    $('body').css('margin-top', headerHeight); //offset for fixed header
  } else {
    $('body').css('margin-top', ''); //offset for fixed header
  }
}

// function menuSlick() {
//   return {
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     rows: 9,
//     arrows: true,
//     fade: true,
//     cssEase: 'linear',
//     adaptiveHeight: true,
//   };
// }

//Home testimonials [WPCR_SHOW]
function testimonial() {
  if ($('.wpcr3_reviews_holder').length) {
    //$('.wpcr3_business').addClass('featured-review');
    $('.wpcr3_review').each(function () {
      //$(this).find('.wpcr3_review_ratingValue').remove();
      let author = $(this).find('.wpcr3_review_author').clone();
      let content = $(this).find('.wpcr3_content');
      $(this).find('.wpcr3_review_author').remove();
      //$(this).find('.wpcr3_review_title').wrapInner('<h3></h3>');
      $(this).find('.wpcr3_review_title').remove();
      $(author).insertAfter(content);
    });
  }
}

//Testimonials [WPCR_SHOW] List restruction

function WPCRList() {
  if ($('.ordinary-page .wpcr3_in_content').length > 0) {
    $('.wpcr3_dotline').remove();
    //$('.wpcr3_show_btn').text('DEJAR SU TESTIMONIO');
    $('.wpcr3_button_1')
      .removeClass('wpcr3_button_1')
      //.addClass('button-container orange_decor with_decor')
      .wrapInner('<div class="button button__blue"></div>');
    let site_name = $(
      '.wpcr3_reviews_holder .wpcr3_item > .wpcr3_item_name'
    ).clone();
    let site_rating = $('.wpcr3_reviews_holder .wpcr3_aggregateRating').clone();
    $('.wpcr3_reviews_holder .wpcr3_item > .wpcr3_item_name').remove();
    $('.wpcr3_reviews_holder .wpcr3_aggregateRating').remove();
    $(site_rating).insertAfter($('.wpcr3_respond_3 .button-container'));
    $(site_name).insertAfter($('.wpcr3_respond_3 .button-container'));
    $('.ordinary-page .wpcr3_in_content div.wpcr3_review').wrapAll(
      '<div class="testimonials_grid"></div>'
    );
    $('.ordinary-page .wpcr3_in_content div.wpcr3_review').each(function () {
      //let logoImage;
      // if ((index + 1) % 2 ) {
      //   logoImage = logo.logo_white;
      // } else {
      //   logoImage = logo.logo_blue;
      // }

      let author = $(this).find('.wpcr3_review_author').clone();
      let title = $(this).find('.wpcr3_review_title').clone();
      let rating = $(this).find('.wpcr3_review_ratingValue');
      let content = $(this).find('.wpcr3_content');
      let date = $(this).find('.wpcr3_review_datePublished');
      $(this).find('.wpcr3_review_author').remove();
      $(this).find('.wpcr3_review_title').remove();
      $(this).find('.wpcr3_review_datePublished').remove();
      //$('<img src="'+ logoImage +'" class="review_logo">').insertBefore(rating);
      $(author).insertAfter(content);
      $(title).insertAfter(rating);
      $(date).insertAfter(author);

      $(this)
        .find('.review_logo, .wpcr3_review_ratingValue')
        .wrapAll('<div class="top_part"></div>');

      /*Hide review after page load*/
      // if(index > 6) {
      //   $(this).addClass('hidden_review');
      // }
    });

    // if($('.ordinary-page .wpcr3_in_content div.wpcr3_review').length > 8) {
    //   $('article .wpcr3_in_content').append(
    //     '<div class="text-center load_wpcr"><a href="javascript:void(0)" class="button light_blue_button">Load More</a></div'
    //   );
    // }
  }
}

function WPCRForm() {
  if ($('.wpcr3_table_2').length > 0) {
    let title = $('.wpcr3_leave_text').text();
    $('.wpcr3_leave_text').html('<h3>' + title + '</h3>');

    let textareaLabel = $('.wpcr3_review_form_review_field_label').clone();
    $('.wpcr3_review_form_review_field_label').remove();
    $(textareaLabel).insertAfter('.wpcr3_review_form_review_field_textarea');
  }
}

function testimonialsHeight($ajax = false) {
  let height = 0;
  let leftHeight = 0;
  let rightHeight = 0;
  let count = $('.wpcr3_reviews_holder .wpcr3_review_item').length;
  if (count > 1) {
    let half = count / 2;
    //First Column
    $('.wpcr3_reviews_holder .wpcr3_review_item:lt(' + half + ')').each(
      function () {
        $(this).addClass('first_half');
        leftHeight += $(this).outerHeight(true, true);
      }
    );
    //Second Column
    $('.wpcr3_reviews_holder .wpcr3_review_item')
      .slice(half)
      .each(function () {
        $(this).addClass('second_half');
        rightHeight += $(this).outerHeight(true, true);
      });
    if (leftHeight > rightHeight) {
      height = leftHeight;
    } else {
      height = rightHeight;
    }
  } else {
    height = $('.wpcr3_reviews_holder .wpcr3_review_item').outerHeight(
      true,
      true
    );
  }

  if ($(window).width() > 1024) {
    if ($ajax) {
      $('.wpcr3_reviews_holder').css(
        'max-height',
        height + $('.wpcr3_pagination').outerHeight(true, true) + 'px'
      );
    } else {
      $('.wpcr3_reviews_holder').css('max-height', height + 'px');
    }
  } else {
    $('.wpcr3_reviews_holder').css('max-height', '');
  }
}

function mapRebuild() {
  $('.footer_locations .map_block').find('.wpgmza-pagination').remove();
  $('.footer_locations .wpgmza_marker_list_class')
    .next('.map_block_content')
    .andSelf()
    .wrapAll('<div' + ' class="map_info"/>');
}

//Pactice areas columns building
function area_columns_widget(slides_per_page) {
  let cur_page = [];
  let $slides = $('.practice-area-shortcode .practice-area-item');
  $slides.each(function (index) {
    cur_page.push(this);
    if (cur_page.length == slides_per_page || index === $slides.length - 1) {
      $(cur_page).wrapAll('<div class="practice-area-col swiper-slide"/>');
      cur_page = [];
    }
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  //Remove nbsp from paragraphs
  // var latestPostSwiper = new Swiper('.js-scroll-x', {
  //   init: !1,
  //   spaceBetween: 45,
  //   slidesPerColumn: 2,
  //   slidesPerView: 1,
  //   slidesPerColumnFill: 'row',
  //   scrollbar: {
  //     el: '.swiper-scrollbar',
  //     hide: !1,
  //     draggable: !0,
  //     dragSize: 83,
  //   },
  // });

  /**
   * Latest post slider
   */
  const latestPostSwiper = new Swiper('.js-scroll-x', {
    modules: [Scrollbar, Grid],
    init: false,
    createElements: true,
    spaceBetween: 45,
    slidesPerColumn: 2,
    slidesPerView: 1,
    slidesPerColumnFill: 'row',
    grid: {
      rows: 2,
    },
    scrollbar: {
      el: '.swiper-scrollbar',
      draggable: true,
      dragSize: '100',
    },
  });
  if ($('.js-scroll-x').length) {
    latestPostSwiper.init();
    // setTimeout(function () {
    // }, 2000);
  }
  $('p').html(function (i, h) {
    return h.replace(/&nbsp;/g, '');
  });

  /**
   * Categories swiper
   */
  // const $count = $('.design-roof__style--icon').is('[data-number]');
  const $count = $('.sidebar').find('.category-slider').data('number');

  const CategoriesSwiper = new Swiper('.category-slider', {
    modules: [Scrollbar, Grid],
    init: false,
    createElements: true,
    spaceBetween: 30,
    slidesPerColumn: $count,
    slidesPerColumnFill: 'row',
    grid: {
      rows: $count,
    },
    scrollbar: {
      el: '.cat-scrollbar',
      draggable: true,
      snapOnRelease: true,
    },
  });

  if ($('.category-slider').length) {
    CategoriesSwiper.init();
    // let $sum = $count * '65';
    // let $swiper = $(this).find('.swiper-wrapper');
    // setTimeout(function () {
    //   $swiper.css('height', parseInt($sum));
    //   console.log($sum);
    // }, 2000);
  }

  // Scroll to ID
  $('a[href^="#"]:not([href="#"])').click(function () {
    let el = $(this).attr('href');
    let headerHeight = $('.header').outerHeight();
    $('html, body').animate(
      {
        scrollTop: $(el).offset().top - headerHeight,
      },
      1000
    );
    return false;
  });

  offcanvasPadding();

  //Contact info animation on mobile and tablet
  $('.contact-menu-icon').click(function () {
    $('#site-phone').slideToggle('slow');
    $('#emails').slideUp('slow');
    $('#locations').slideUp('slow');
    $('.top-bar').slideUp('slow');
  });
  $('.email-menu-icon').click(function () {
    $('#emails').slideToggle('slow');
    $('#locations').slideUp('slow');
    $('#site-phone').slideUp('slow');
    $('.top-bar').slideUp('slow');
  });
  $('.address-menu-icon').click(function () {
    $('#locations').slideToggle('slow');
    $('#site-phone').slideUp('slow');
    $('#emails').slideUp('slow');
    $('.top-bar').slideUp('slow');
  });
  $('.title-bar').click(function () {
    $('#locations').slideUp('slow');
    $('#site-phone').slideUp('slow');
    $('#emails').slideUp('slow');
  });

  //Third level menu positioning
  $('.three-level-menu .submenu .is-dropdown-submenu-parent').hover(
    function () {
      let position = $(this).position();
      $('.three-level-menu .submenu .submenu').css({
        top: position.top + 73,
      });
    }
  );

  /**
   * Make elements equal height
   */

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  function spanPosition() {
    $('.practice-area-slider-shortcode .practice-area-item').each(function () {
      let offsetLeft = $(this).offset().left;
      $(this).find('.overlay span').css({
        left: -offsetLeft,
        width: document.body.clientWidth,
      });
    });

    $('.practice-area-shortcode .practice-area-item').each(function () {
      let offsetLeft = $(this).offset().left;
      let offsetLeft2 = $('.practice-area-shortcode').offset().left;
      let offsetTop = $(this).offset().top;
      let offsetTop2 = $('.practice-area-shortcode').offset().top;
      $(this)
        .find('.overlay span')
        .css({
          left: -offsetLeft + offsetLeft2,
          top: -offsetTop + offsetTop2,
          width: $('.practice-area-shortcode').outerWidth(),
          height: $('.practice-area-shortcode').outerHeight(true, true) + 12,
        });
    });
  }

  $(document)
    .find('.practice-scrollbar')
    .mouseup(function () {
      setTimeout(function () {
        spanPosition();
      }, 300);
    });

  let swiper = new Swiper('.practice-area-slider-shortcode', {
    init: false,
    swipeHandler: '.practice_container',
    autoHeight: true,
    grabCursor: false,
    allowTouchMove: false,
    scrollbar: {
      el: '.practice-scrollbar',
      hide: false,
      draggable: true,
      dragSize: 86,
    },
    on: {
      init: function () {
        let activeIndex = this.activeIndex;
        let $this = this;
        setTimeout(function () {
          $this.slides
            .eq(activeIndex)
            .addClass('swiper-slide-visible_area')
            .next()
            .addClass('swiper-slide-visible_area')
            .next()
            .addClass('swiper-slide-visible_area')
            .next()
            .addClass('swiper-slide-visible_area')
            .next()
            .addClass('swiper-slide-visible_area');
        }, 50);
        spanPosition();
      },
      slideChange: function () {
        let activeIndex = this.activeIndex;
        let $this = this;
        setTimeout(function () {
          $this.slides.removeClass('swiper-slide-visible_area');
          $this.slides
            .eq(activeIndex)
            .addClass('swiper-slide-visible_area')
            .next()
            .addClass('swiper-slide-visible_area')
            .next()
            .addClass('swiper-slide-visible_area')
            .next()
            .addClass('swiper-slide-visible_area')
            .next()
            .addClass('swiper-slide-visible_area');
        }, 250);
      },
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
      },
      // when window width is >= 520px
      520: {
        slidesPerView: 2,
        //initialSlide: 1,
      },
      // when window width is >= 767px
      767: {
        slidesPerView: 3,
        //initialSlide: 1,
      },
      // when window width is >= 1300px
      1300: {
        slidesPerView: 4,
        //initialSlide: 2,
      },
      // when window width is >= 1600px
      1600: {
        slidesPerView: 5,
        //initialSlide: 2,
      },
    },
  });

  if ($('.practice-area-slider-shortcode').length) {
    swiper.init();
  }

  //Practice areas 2 rows

  var swiper2 = new Swiper('.practice-area-shortcode', {
    init: false,
    //slidesPerView: 5, // or 'auto'
    slidesPerColumn: 1,
    scrollbar: {
      el: '.practice-scrollbar',
      hide: false,
      draggable: true,
      dragSize: 86,
    },
    on: {
      init: function () {
        // Total number of slides
        // var totalNumberOfSlides = swiper2.slides.length;
        // // half count of slides
        // var halfCount = totalNumberOfSlides / 2;
        //
        // $('.practice-area .practice-area-item').each(function (index) {
        //   if(index > halfCount - 1) {
        //     $(this).addClass('second_row')
        //   }
        // })

        $(window).load(function () {
          setTimeout(function () {
            spanPosition();
          }, 500);
        });
      },
      slideChange: function () {
        setTimeout(function () {
          spanPosition();
        }, 250);
      },
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
      },
      // when window width is >= 540px
      540: {
        slidesPerView: 2,
      },
      // when window width is >= 767px
      767: {
        slidesPerView: 3,
      },
      // when window width is >= 1025px
      1025: {
        slidesPerView:
          $('.attorney_profile__content').length || $('.content_side').length
            ? 2
            : 3,
      },
      // when window width is >= 1201px
      1201: {
        slidesPerView:
          $('.attorney_profile__content').length || $('.content_side').length
            ? 3
            : 4,
      },
      // when window width is >= 1600px
      1600: {
        slidesPerView:
          $('.attorney_profile__content').length || $('.content_side').length
            ? 4
            : 5,
      },
      // when window width is >= 1900px
      1900: {
        slidesPerView: 5,
      },
    },
  });

  if ($('.practice-area-shortcode').length) {
    area_columns_widget(2);
    swiper2.init();
    $('.row_1').matchHeight();
    $('.row_2').matchHeight();
  }

  //Widgets sliders
  $('.sidebar-practice-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    adaptiveHeight: true,
    slidesPerRow: 2,
    rows: 4,
    //nextArrow: '.practice-arrow-next',
    //prevArrow: '.practice-arrow-prev',
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 1,
          rows: 4,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 3,
          rows: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 2,
          rows: 2,
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          slidesPerRow: 1,
          rows: 2,
        },
      },
    ],
  });

  /*Review Steam slider js*/
  /*Uncomment if you need review stream slider in widget*/
  /*$('.sidebar-testimonials-slider #reviewstream').slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: true,
   adaptiveHeight: true,
   slide: '.review'
 });*/

  $('.testimonials #reviewstream').slick({
    slidesToShow: 1,
    infinite: true,
    speed: 1000,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    adaptiveHeight: true,
    slide: '.review',
    rows: 0,
  });

  /*Wp custom reviews slider js*/
  /*Comment or delete if you use review stream slider in widget*/
  $('.sidebar-testimonials-slider .wpcr3_reviews_holder').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    adaptiveHeight: true,
    slide: '.wpcr3_review_item',
  });

  $('.awards-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    adaptiveHeight: true,
  });

  $('.js-cases-slider').slick({
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    slidesPerRow: 1,
    dots: false,
    arrows: false,
    adaptiveHeight: true,
  });

  $('.footer_testimonials .wpcr3_reviews_holder').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    adaptiveHeight: true,
    slide: '.wpcr3_review_item',
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          arrows: false,
        },
      },
    ],
  });

  /*Dropdown archive widget*/

  $('.widget_archive_widget').on('click', '.placeholder', function () {
    $(this).next('.dropdown_list').toggle();
    $('.matchHeight2').matchHeight();
  });

  /* ------ Popular/Recent Posts Widget JS------*/
  function posts_slider_init($selector) {
    $($selector).slick({
      cssEase: 'ease',
      fade: false,
      autoplay: true,
      autoplaySpeed: 8000,
      infinite: true,
      dots: false,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
    });
  }

  posts_slider_init('.latest-post-slider');

  $('#popular-blog-body').css('display', 'none');
  $('#latest-posts-button').addClass('active');

  $('#latest-posts-button').click(function () {
    $('#latest-blog-body').fadeIn('slow').css('display', 'block');
    $('#popular-blog-body').fadeOut('fast');
    $(this).addClass('active');
    $('#popular-posts-button').removeClass('active');

    $('.latest-post-slider').slick('unslick');
    posts_slider_init('#latest-blog-body .latest-post-slider');
  });

  $('#popular-posts-button').click(function () {
    $('#latest-blog-body').fadeOut('fast');
    $('#popular-blog-body').fadeIn('slow').css('display', 'block');
    $(this).addClass('active');
    $('#latest-posts-button').removeClass('active');
    $('.latest-post-slider').slick('unslick');
    posts_slider_init('#popular-blog-body .latest-post-slider');
  });

  /* ------ End Popular/Recent Posts Widget JS------*/

  $('.profile-details__item--details').each(function () {
    var tr = $(this).find('tr').length;
    var td = $(this).find('tr td').length;
    var cells = td / tr;
    if (cells == 2) {
      $(this).find('tr td').css('width', '50%');
    } else {
      $(this)
        .find('tr td')
        .css('width', 'calc(100% / ' + cells + ')');
      $(this).find('tr td:last-of-type').css('width', '15%');
    }
  });

  //offcanvas menu

  // $('.open_offcanvas').on('click', function () {
  //   $('.request_form, .offcanvas__left, body').toggleClass('active top');
  //   $('.offcanvas__middle').css('width', '0%');
  //   $('.offcanvas__middle .submenu').empty();
  //   $('.open_submenu').removeClass('active');
  //   $(this).toggleClass('opened');
  //
  //   if ($(this).hasClass('opened')) {
  //     $('.offcanvas').css({
  //       height: $('.header__top').outerHeight(),
  //       'min-height': '0px',
  //     });
  //
  //     if ($('.offcanvas').hasClass('hidden')) {
  //       $('.offcanvas')
  //         .removeClass('hidden')
  //         .delay(600)
  //         .promise()
  //         .done(function () {
  //           $('.offcanvas')
  //             .css({
  //               height: '100vh',
  //               top: 0,
  //               'min-height': '768px',
  //             })
  //             .delay(600)
  //             .promise()
  //             .done(function () {
  //               $(
  //                 '.offcanvas__left > *, .offcanvas__middle > *, .request_form > *'
  //               ).css({
  //                 opacity: 1,
  //               });
  //             });
  //         });
  //     }
  //   } else {
  //     //$('.offcanvas__left, .request_form').css('flex','0 0 50%')
  //     $('.offcanvas__left > *, .offcanvas__middle > *, .request_form > *')
  //       .css({
  //         opacity: 0,
  //       })
  //       .delay(600)
  //       .promise()
  //       .done(function () {
  //         $('.offcanvas')
  //           .css({
  //             height: $('.header__top').outerHeight(),
  //             'min-height': '0px',
  //           })
  //           .delay(600)
  //           .promise()
  //           .done(function () {
  //             $('.offcanvas').addClass('hidden');
  //           });
  //       });
  //   }
  // });
  //
  // $('.close_menu').on('click', function () {
  //   $('.request_form, .offcanvas__left, body').toggleClass('active top');
  //   $('.offcanvas__middle').css('width', '0%');
  //   $('.offcanvas__middle .submenu').empty();
  //   $('.open_submenu').removeClass('active');
  //   $('.open_offcanvas').removeClass('opened');
  //   $('.offcanvas__left > *, .offcanvas__middle > *, .request_form > *')
  //     .css({
  //       opacity: 0,
  //     })
  //     .delay(600)
  //     .promise()
  //     .done(function () {
  //       $('.offcanvas')
  //         .css({
  //           height: $('.header__top').outerHeight(),
  //           'min-height': '0px',
  //         })
  //         .delay(600)
  //         .promise()
  //         .done(function () {
  //           $('.offcanvas').addClass('hidden');
  //         });
  //     });
  // });
  //
  // $('.open_submenu').on('click', function () {
  //   var submenu = $(this).siblings('.submenu').html();
  //   if ($(this).hasClass('active')) {
  //     $(this).removeClass('active');
  //     $('.offcanvas__middle').css('width', '0%');
  //     $('.offcanvas__middle .submenu').empty();
  //     $('.request_form, .offcanvas__left').removeClass('opened');
  //     $('.offcanvas > .offcanvas__left').css('flex-basis', '50%');
  //     $('.offcanvas > .request_form').css('flex-basis', '50%');
  //     $('.offcanvas__middle .submenu').slick('unslick');
  //   } else {
  //     $(this)
  //       .parent()
  //       .siblings('.menu-item')
  //       .find('.open_submenu')
  //       .removeClass('active');
  //     $('.offcanvas > .offcanvas__left').css('flex', '1 0 33%');
  //     $('.offcanvas > .offcanvas__middle').css('width', '25%');
  //     $('.offcanvas > .request_form').css('flex', '1 0 33%');
  //
  //     setTimeout(function () {
  //       $('.offcanvas__middle .submenu').slick(menuSlick());
  //     }, 500);
  //     $(this).addClass('active');
  //     $('.request_form, .offcanvas__left').addClass('opened');
  //     if ($('.offcanvas__middle .submenu').hasClass('slick-slider')) {
  //       $('.offcanvas__middle .submenu').slick('unslick');
  //       $('.offcanvas__middle .submenu').html(submenu);
  //       $('.offcanvas__middle .submenu').slick(menuSlick());
  //     } else {
  //       $('.offcanvas__middle .submenu').html(submenu);
  //       setTimeout(function () {
  //         $('.offcanvas__middle .submenu').slick(menuSlick());
  //       }, 500);
  //     }
  //   }
  // });

  if ($(window).width() < 1025) {
    $('li.is-accordion-submenu-parent').off('click');
    // $('li.is-accordion-submenu-parent').unbind();
  }

  $(document).on('click', '.menu-item.is-accordion-submenu-parent > a', (e) => {
    let link = e.target.href;
    window.location = link;
  });

  $('.open_submenu').on('click', function () {
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    } else {
      $(this)
        .parent()
        .siblings('.menu-item')
        .find('.open_submenu')
        .removeClass('active');
      $(this).addClass('active');
    }

    if ($(window).width() < 1025) {
      let submenu = $(this).siblings('.submenu');
      submenu.slideToggle();
    }
  });

  //Request form
  $('.contact-us__maps__btn .button').on('click', function () {
    $('.request_form_full, .request, body').toggleClass('active');
  });

  $('.request_mobile a').on('click', function () {
    $('.request_form_full').toggleClass('active');
  });

  $('.request, .close_form_full, .request_header').on('click', function () {
    $('.request_form_full').toggleClass('active');
  });

  //Open Side form after submit
  if ($('.gform_confirmation_wrapper, .gform_validation_error').length > 0) {
    // code to be trigger when confirmation page is loaded
    $('.request_form_full, .request, body').toggleClass('active');
  }

  /*Wrapp to p tag in single-post content editor*/

  $('.single-page article .post-wrapper .post__inner h2').each(function () {
    $(this).nextUntil('h2').wrapAll('<div class="pg-wrapper"></div>');
  });

  /*Testimonials plugin*/

  testimonial();
  WPCRList();
  WPCRForm();

  testimonialsHeight();

  $('.wpcr3_pagination').on('click', '.wpcr3_a', function () {
    $(document).ajaxStop(function () {
      testimonialsHeight(true);
    });
  });

  /**
   * AJAX filtering Case Results
   */

  /* global ajax_url */

  $('.js-case-categories').on('click', '.js-case-cat-btn', function (e) {
    e.preventDefault();
    $('.js-case-cat-btn').removeClass('is-active');
    $(this).addClass('is-active');
    var data = {
      action: 'filter_cases',
      category: $(this).data('term'),
    };
    $.ajax({
      type: 'POST',
      data: data,
      dataType: 'json',
      url: ajax_url.url,
    }).done(function (response) {
      $('.case .cases_items').html(response.html);
      if (response.show_button) {
        $('.more_button').show();
      } else {
        $('.more_button').hide();
      }
    });
  });

  /**
   *  Ajax Load More Case Results
   */

  $(document).on('click', '.more_cases', function () {
    var pageId = $('.case').data('id');
    var data = {
      action: 'cases',
      pageId: pageId,
      visible: $('.case__wrapper').length,
      category: $('.js-case-cat-btn.is-active').data('term'),
    };
    $.ajax({
      type: 'POST',
      data: data,
      dataType: 'json',
      url: ajax_url.url,
    }).done(function (response) {
      $('.case .cases_items').append(response.html);
      if (response.show_button) {
        $('.more_button').show();
      } else {
        $('.more_button').hide();
      }
    });
  });

  $('#ajaxPreloader')
    .hide() // Hide it initially
    .ajaxStart(function () {
      $(this).show();
    })
    .ajaxStop(function () {
      $(this).hide();
    });

  /**
   * Testimonial Tabs
   */

  // Show the first tab by default
  $('.testimonials_tabs .tabs-stage > div').hide();
  $('.testimonials_tabs .tabs-stage > div:first').show();
  $('.testimonials_tabs .tabs-nav li:first').addClass('tab-active');

  // Change tab class and display content
  $('.testimonials_tabs .tabs-nav li').on('click', function (event) {
    event.preventDefault();
    $('.testimonials_tabs .tabs-nav li').removeClass('tab-active');
    $(this).addClass('tab-active');
    $('.testimonials_tabs .tabs-stage > div').hide();
    $($(this).find('a').attr('href')).show();
  });

  //Featured section pop-up slider
  $('.videos-slider-shortcode-large').slick({
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
    asNavFor: '.videos-slider-shortcode-thumbnail',
  });

  $('.videos-slider-shortcode-thumbnail').slick({
    fade: false,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    dots: false,
    arrows: true,
    asNavFor: '.videos-slider-shortcode-large',
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $('.foobox_slider').on('click', function () {
    setTimeout(function () {
      $('.videos-slider-shortcode-large').slick('resize');
      $('.videos-slider-shortcode-thumbnail').slick('resize');
    }, 500);
  });

  resizeVideo();
  $('.matchHeight').matchHeight();
  $('.postHeight').matchHeight();
  $('.matchHeight2').matchHeight();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  fixedHeader();
  offcanvasPadding();

  // setTimeout(function () {
  //   $('.matchHeight').matchHeight();
  //   $('.postHeight').matchHeight();
  //   $('.matchHeight2').matchHeight();
  // }, 500);

  //Shortcodes sliders
  setTimeout(function () {
    $('.awards-slider-shortcode').slick({
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 6,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToScroll: 1,
      variableWidth: false,
      centerMode: false,
      responsive: [
        {
          breakpoint: 1601,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            centerMode: false,
            variableWidth: false,
          },
        },
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            centerMode: false,
            variableWidth: false,
          },
        },
        {
          breakpoint: 801,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            centerMode: false,
            variableWidth: false,
          },
        },
        {
          breakpoint: 551,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            centerMode: false,
            variableWidth: false,
          },
        },
      ],
    });

    $('.home-attorneys__slider').slick({
      arrows: false,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
    });
  }, 300);

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  mapRebuild();
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
  offcanvasPadding();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
  fixedHeader();
});
